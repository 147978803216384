import "./src/styles/global.css"

export const onClientEntry = () => {
  
};

const popupNotIncludedPages = [
  "/checkout-stripe-checkout-success/",
  "/privacy-policy/",
  "/privacy-policy/"
]

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (!popupNotIncludedPages.includes(location.pathname)){
    require('./src/lib/popnboost.js');
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  window.scrollTo(0, 0);
  return true;
};